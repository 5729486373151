import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private themeValue:number;

  constructor() { }

  setThemeValue(value:number):void {
    this.themeValue = value;
  }

  getThemevalue():number {
    return this.themeValue;
  }
}
