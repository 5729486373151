import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, APP_INITIALIZER, Injector } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MDBSpinningPreloader, MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';

import { AppComponent } from './app.component';
import { HomeComponent } from './views/home/home.component';
import { AgriculturalComponent } from './views/agricultural/agricultural.component';
import { ClearTitleComponent } from './views/clear-title/clear-title.component';
import { CurrentMapComponent } from './views/current-map/current-map.component';
import { LegalAccessComponent } from './views/legal-access/legal-access.component';
import { OwnershipTypeComponent } from './views/ownership-type/ownership-type.component';
import { PropertyInMindComponent } from './views/property-in-mind/property-in-mind.component';
import { PrevLandUseComponent } from './views/prev-land-use/prev-land-use.component';
import { SubsurfaceRightsComponent } from './views/subsurface-rights/subsurface-rights.component';
import { TrailsRoadsComponent } from './views/trails-roads/trails-roads.component';
import { WaterBodiesComponent } from './views/water-bodies/water-bodies.component';
import { RegionComponent } from './views/region/region.component';
import { TexasMapComponent } from './components/texas-map/texas-map.component';
import { LandSizeComponent } from './views/land-size/land-size.component';
import { PrimaryGoalComponent } from './views/primary-goal/primary-goal.component';
import { SecondaryGoalComponent } from './views/secondary-goal/secondary-goal.component';
import { WildlifeComponent } from './views/wildlife/wildlife.component';
import { TimberComponent } from './views/timber/timber.component';
import { ReportComponent } from './views/report/report.component';
import { EndangeredComponent } from './views/endangered/endangered.component';
import { InfoHostDirective } from './modal/info-host.directive';
import { InfoModalComponent } from './components/info-modal/info-modal.component';
import { OwnershipInfoComponent } from './modal/ownership-info/ownership-info.component';
import { PropertyInMindInfoComponent } from './modal/property-in-mind-info/property-in-mind-info.component';
import { AgriculturalInfoComponent } from './modal/agricultural-info/agricultural-info.component';
import { ClearTitleInfoComponent } from './modal/clear-title-info/clear-title-info.component';
import { CurrentMapInfoComponent } from './modal/current-map-info/current-map-info.component';
import { EndangeredInfoComponent } from './modal/endangered-info/endangered-info.component';
import { LandSizeInfoComponent } from './modal/land-size-info/land-size-info.component';
import { LegalAccessInfoComponent } from './modal/legal-access-info/legal-access-info.component';
import { PrimaryGoalInfoComponent } from './modal/primary-goal-info/primary-goal-info.component';
import { RegionInfoComponent } from './modal/region-info/region-info.component';
import { SecondaryGoalInfoComponent } from './modal/secondary-goal-info/secondary-goal-info.component';
import { SubsurfaceRightsInfoComponent } from './modal/subsurface-rights-info/subsurface-rights-info.component';
import { TimberInfoComponent } from './modal/timber-info/timber-info.component';
import { TrailsRoadsInfoComponent } from './modal/trails-roads-info/trails-roads-info.component';
import { WaterBodiesInfoComponent } from './modal/water-bodies-info/water-bodies-info.component';
import { WildlifeInfoComponent } from './modal/wildlife-info/wildlife-info.component';
import { PrevLandUseInfoComponent } from './modal/prev-land-use-info/prev-land-use-info.component';
import { IconsBarComponent } from './components/icons-bar/icons-bar.component';
import { LOCATION_INITIALIZED } from '@angular/common';
import { ReportInfoComponent } from './modal/report-info/report-info.component';
import { NgxSpinnerModule } from 'ngx-spinner'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'en'
      translate.setDefaultLang('en');
      translate.use(langToSet).subscribe(() => {
        //console.info(`Successfully initialized '${langToSet}' language.'`);
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AgriculturalComponent,
    ClearTitleComponent,
    CurrentMapComponent,
    LegalAccessComponent,
    OwnershipTypeComponent,
    PropertyInMindComponent,
    PrevLandUseComponent,
    SubsurfaceRightsComponent,
    TrailsRoadsComponent,
    WaterBodiesComponent,
    RegionComponent,
    TexasMapComponent,
    LandSizeComponent,
    PrimaryGoalComponent,
    SecondaryGoalComponent,
    WildlifeComponent,
    TimberComponent,
    ReportComponent,
    EndangeredComponent,
    InfoHostDirective,
    InfoModalComponent,
    OwnershipInfoComponent,
    PropertyInMindInfoComponent,
    AgriculturalInfoComponent,
    ClearTitleInfoComponent,
    CurrentMapInfoComponent,
    EndangeredInfoComponent,
    LandSizeInfoComponent,
    LegalAccessInfoComponent,
    PrimaryGoalInfoComponent,
    RegionInfoComponent,
    SecondaryGoalInfoComponent,
    SubsurfaceRightsInfoComponent,
    TimberInfoComponent,
    TrailsRoadsInfoComponent,
    WaterBodiesInfoComponent,
    WildlifeInfoComponent,
    PrevLandUseInfoComponent,
    IconsBarComponent,
    ReportInfoComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule, 
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MDBBootstrapModulesPro.forRoot(),
    NgxSpinnerModule
  ],
  providers: [ 
    MDBSpinningPreloader,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }
   ],
  bootstrap: [AppComponent],
  schemas:[NO_ERRORS_SCHEMA],
  entryComponents: [
    OwnershipInfoComponent,
    PropertyInMindInfoComponent,
    AgriculturalInfoComponent,
    ClearTitleInfoComponent,
    CurrentMapInfoComponent,
    EndangeredInfoComponent,
    LandSizeInfoComponent,
    LegalAccessInfoComponent,
    PrimaryGoalInfoComponent,
    RegionInfoComponent,
    SecondaryGoalInfoComponent,
    SubsurfaceRightsInfoComponent,
    TimberInfoComponent,
    TrailsRoadsInfoComponent,
    WaterBodiesInfoComponent,
    WildlifeInfoComponent,
    PrevLandUseInfoComponent,
    ReportInfoComponent
  ]
})
export class AppModule { }
