import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewManagementService } from '../../services/view-management.service';
import { UserOptions } from '../../models/UserOptions';
import { ThemeService } from '../../services/theme.service';
import { ResizeService } from '../../services/resize.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild("svgDiv") svgDiv: ElementRef;

  _viewBox: string = "";
  _topPolygonVertices: string = "";
  _bottomPolygonVertices: string = "";
  _btnTextX: string = "";

  themeValue:number;

  constructor(
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private themeService: ThemeService,
    private resizeService: ResizeService
  ) { 
    this.themeValue = themeService.getThemevalue();
  }

  ngOnInit() {
    //this.updateDeviceWidth(window.innerWidth);
    this.resizeService.update.subscribe(result => {
      this.updateButtonsSVG(result.newWidth);
    });

    this.resizeService.updateSize(window.innerWidth, window.innerHeight);
  }

  // onResize(event) {
  //   //this.updateDeviceWidth(event.target.innerWidth);
  // }

  updateButtonsSVG(width: number) {
    let viewBoxWidth: string = "";

    //console.log(this.svgDiv.nativeElement.offsetWidth);

    if (width > 1199) {
      //viewBoxWidth = "896";
      this._btnTextX = "570";
    }
    else if (width > 991) {
      //viewBoxWidth = "960";
      this._btnTextX = "480";
    }
    else if (width > 767) {
      //viewBoxWidth = "720";
      this._btnTextX = "360";
    }
    else if (width > 575) {
      //viewBoxWidth = "540";
      this._btnTextX = "288";
    }
    else {
      let viewBoxWidthNumber: number = Math.round((width * 100) / 150);
      //viewBoxWidth = viewBoxWidthNumber.toString();
      this._btnTextX = Math.round(viewBoxWidthNumber / 2).toString();
    }

    viewBoxWidth = this.svgDiv.nativeElement.offsetWidth.toString();
    this._btnTextX = Math.round(this.svgDiv.nativeElement.offsetWidth / 2).toString();
    //let viewBoxWidth: string = ((width * 100) / 150).toString();
    this._viewBox = "0 0 " + viewBoxWidth + " 100";
    this._topPolygonVertices = "0,20 0,40 " + viewBoxWidth + ",80 " + viewBoxWidth + ",0";
    this._bottomPolygonVertices = "0,40 0,100 " + viewBoxWidth + ",100 " + viewBoxWidth + ",80";
  }

  switchLanguage() {
    let targetLanguage: string = "";

    if (this.translate.currentLang === 'es') {
      targetLanguage = 'en';
    }
    else {
      targetLanguage = 'es';
    }

    this.translate.use(targetLanguage);
  };

  getStarted(): void {
    this.viewManagementService.setOwnershipTypeView(new UserOptions());
  }
}
