import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[info-host]'
})
export class InfoHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
