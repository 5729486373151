import { Injectable, Output, EventEmitter } from '@angular/core';
import { ResizeResult } from '../models/ResizeResult';

@Injectable({
  providedIn: 'root'
})
export class ResizeService {

  @Output() update: EventEmitter<ResizeResult> = new EventEmitter();

  constructor() { }

  updateSize(newWidth: number, newHeight: number): void {
    let result: ResizeResult = new ResizeResult();
    result.newWidth = newWidth;
    if (newWidth > newHeight) {
      result.isLandscape = true;
    }
    else {
      result.isLandscape = false
    }

    this.update.emit(result);
  }
}
