import { Component, OnInit, Input } from '@angular/core';
import { UserOptions } from '../../models/UserOptions';
import { ViewManagementService } from '../../services/view-management.service';
import { ThemeService } from '../../services/theme.service';
import { ButtonClassService } from '../../services/button-class.service';

@Component({
  selector: 'app-texas-map',
  templateUrl: './texas-map.component.html',
  styleUrls: ['./texas-map.component.scss'],

})
export class TexasMapComponent implements OnInit {

  @Input() currentOptions: UserOptions;

  hoveredRegion: string = "";

  private themeValue: number;

  constructor(
    private viewManagementService: ViewManagementService,
    private themeService: ThemeService,
    private buttonClassService: ButtonClassService
  ) {
    this.themeValue = this.themeService.getThemevalue();
  }

  ngOnInit() {
    //console.log(this.currentOptions);
  }

  selectRegion(region: string): void {
    this.currentOptions.region = region;
    //this.userOptionsService.updateOptions(this.currentOptions);
    this.viewManagementService.setLandSizeView(this.currentOptions);
  }

  mouseenter(hoveredPath: string): void {

    this.hoveredRegion = hoveredPath;
  }

  mouseleave(): void {
    this.hoveredRegion = "";
  }

  getClass(isSelected: boolean, isText: boolean, isHovered: boolean): string {
    let resultClass: string = this.buttonClassService.buildMapRegionClass(isSelected, this.themeValue, isText, isHovered);
    return resultClass;
  };
}
