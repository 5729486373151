import { Injectable, Output, EventEmitter, Component } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() open: EventEmitter<null> = new EventEmitter();

  //private currentInfoComponent:Component;

  constructor() { }

  updateInfo(infoComponent:any):void {
    this.update.emit(infoComponent);
  }

  openModal():void {
    this.open.emit(null);
  }
}
