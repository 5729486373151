import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-current-map-info',
  templateUrl: './current-map-info.component.html',
  styleUrls: ['./current-map-info.component.scss']
})
export class CurrentMapInfoComponent implements OnInit {

  constructor(
    private translate: TranslateService
  ) { }
  
  ngOnInit() {
  }

}
