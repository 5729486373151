import { Component, OnInit, Input } from '@angular/core';
import { UserOptions } from '../../models/UserOptions';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';
import { ViewManagementService } from '../../services/view-management.service';
import { ThemeService } from '../../services/theme.service';
import { ButtonClassService } from '../../services/button-class.service';
import { ReportService } from '../../services/report.service';
import { ModalService } from '../../services/modal.service';
import { ReportInfoComponent } from '../../modal/report-info/report-info.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  @Input() currentOptions: UserOptions;

  private themeValue: number;

  previousView: string;

  constructor(
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private themeService: ThemeService,
    private buttonClassService: ButtonClassService,
    private reportService: ReportService,
    private modalService: ModalService,
    private spinner: NgxSpinnerService
  ) {
    this.themeValue = this.themeService.getThemevalue();
    this.modalService.updateInfo(ReportInfoComponent);
  }

  ngOnInit() {
    if (this.currentOptions.primaryGoal !== "wildlife" && this.currentOptions.secondaryGoal !== "wildlife") {
      this.previousView = "wildlife";
      //this.viewManagementService.setWildlifeView(this.currentOptions);
    }
    else if (this.currentOptions.secondaryGoal === "timber") {
      this.previousView = "timber";
      //this.viewManagementService.setTimberView(this.currentOptions);
    }
    else {
      this.previousView = "secondaryGoal";
      //this.viewManagementService.setSecondaryGoalView(this.currentOptions);
    }
  }

  getClass(isSelected: boolean): string {
    let resultClass: string = this.buttonClassService.buildClass(isSelected, this.themeValue);
    return resultClass;
  };

  getReport() {
    this.spinner.show();
    const reportParamsString: string = JSON.stringify(this.buildReportParams());
    this.reportService.getReport({ content: reportParamsString }).subscribe(
      response => {
        //const reportUrl:string = response.pdfDocument.replace("D:/Applications/TexasForestInfo", "http://tfs-26154");
        const reportUrl: string = response.pdfDocument.replace("D:/Applications/gotLand", "https://gotland.tfs.tamu.edu");
        window.open(reportUrl, "_blank")
        this.spinner.hide();
      }
    );
  }

  private buildReportParams(): object {
    let reportParams: object = {};

    let sections: number[] = [];

    if (this.currentOptions.propertyInMind !== false) {
      if (this.currentOptions.currentMap === true) {
        sections.push(2);
      }

      if (this.currentOptions.prevLandUse === true) {
        sections.push(3);
      }

      if (this.currentOptions.clearTitle === true) {
        sections.push(4);
      }

      if (this.currentOptions.agricultural === false) {
        sections.push(5);
      }

      if (this.currentOptions.trailsRoads === false) {
        sections.push(6);
      }

      if (this.currentOptions.legalAccess === true) {
        sections.push(7);
      }
    }

    if (this.currentOptions.subsurfaceRights === false) {
      sections.push(8);
    }

    if (this.currentOptions.waterBodies === false) {
      sections.push(9);
    }

    if (this.currentOptions.endangered === false) {
      sections.push(10);
    }

    if (this.currentOptions.primaryGoal !== "timber" && this.currentOptions.secondaryGoal !== "timber") {
      sections.push(12);
      sections.push(13);
      sections.push(14);
      sections.push(15);
    }
    else {
      if (this.currentOptions.landSize !== "lessThan10") {
        sections.push(12);
      }

      if (this.currentOptions.timberType === "hardwood") {
        sections.push(14);
      }
      else if (this.currentOptions.timberType === "pine") {
        sections.push(15);
      }
    }

    if (this.currentOptions.primaryGoal !== "orchard" && this.currentOptions.secondaryGoal !== "orchard") {
      sections.push(16);
    }

    if (this.currentOptions.primaryGoal !== "livestock" && this.currentOptions.secondaryGoal !== "livestock") {
      sections.push(17);
      sections.push(18);
    }
    else if (this.currentOptions.landSize === "moreThan500") {
      sections.push(17);
    }

    if (this.currentOptions.primaryGoal !== "crops" && this.currentOptions.secondaryGoal !== "crops") {
      sections.push(19);
    }

    if (this.currentOptions.primaryGoal !== "wildlife" && this.currentOptions.secondaryGoal !== "wildlife" && this.currentOptions.thirdGoal !== true) {
      sections.push(20);
    }

    const sortedReportSections: number[] = sections.sort((n1, n2) => n1 - n2);
    reportParams["sections"] = JSON.stringify(sortedReportSections);

    if (this.translate.currentLang === 'en') {
      reportParams["language"] = "English";
    }
    else {
      reportParams["language"] = "Spanish";
    }

    return reportParams;
  }

  getSpinnerColor():string {
    let spinnerColor:string = "";
    switch (this.themeValue)
    {
      case 1:
      spinnerColor = "rgba(68,85,113,1)";
      break;

      case 2:
      spinnerColor = "rgba(81, 35, 20, 1)";
      break;

      case 3:
      spinnerColor = "rgba(89,117,193,1)";
      break;

      default:
      break;
    }

    return spinnerColor;
  }
}
