import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-prev-land-use-info',
  templateUrl: './prev-land-use-info.component.html',
  styleUrls: ['./prev-land-use-info.component.scss']
})
export class PrevLandUseInfoComponent implements OnInit {

  constructor(
    private translate: TranslateService
  ) { }
  
  ngOnInit() {
  }

}
