import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-property-in-mind-info',
  templateUrl: './property-in-mind-info.component.html',
  styleUrls: ['./property-in-mind-info.component.scss']
})
export class PropertyInMindInfoComponent implements OnInit {

  constructor(
    private translate: TranslateService
  ) { }
  
  ngOnInit() {
  }

}
