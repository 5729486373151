import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-clear-title-info',
  templateUrl: './clear-title-info.component.html',
  styleUrls: ['./clear-title-info.component.scss']
})
export class ClearTitleInfoComponent implements OnInit {

  constructor(
    private translate: TranslateService
  ) { }
  
  ngOnInit() {
  }

}
