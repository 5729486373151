import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';
import { ViewManagementService } from '../../services/view-management.service';
import { UserOptions } from '../../models/UserOptions';
import { ModalService } from '../../services/modal.service';
import { WildlifeInfoComponent } from '../../modal/wildlife-info/wildlife-info.component';
import { ThemeService } from '../../services/theme.service';
import { ButtonClassService } from '../../services/button-class.service';

@Component({
  selector: 'app-wildlife',
  templateUrl: './wildlife.component.html',
  styleUrls: ['./wildlife.component.scss']
})
export class WildlifeComponent implements OnInit {

  @Input() currentOptions:UserOptions;

  private themeValue:number;

  previousView:string;

  constructor(
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private modalService: ModalService,
    private themeService: ThemeService,
    private buttonClassService: ButtonClassService
  ) { 
    this.themeValue = this.themeService.getThemevalue();
  }

  ngOnInit() {
    this.modalService.updateInfo(WildlifeInfoComponent);

        if (this.currentOptions.secondaryGoal === "timber")
    {
      this.previousView = "timber";
      //this.viewManagementService.setTimberView(this.currentOptions);
    }
    else {
      this.previousView = "secondaryGoal";
      //this.viewManagementService.setSecondaryGoalView(this.currentOptions);
    }
    //console.log(this.currentOptions);
  }

  goToReportView(evt):void {
    if (evt.target.id === "yesWildBtn")
    {
      this.currentOptions.thirdGoal = true;
    }
    else {
      this.currentOptions.thirdGoal = false;
    }

    //this.userOptionsService.updateOptions(this.currentOptions);
    this.viewManagementService.setReportView(this.currentOptions);
   
  }

  getClass(isSelected:boolean):string {
    let resultClass:string = this.buttonClassService.buildClass(isSelected, this.themeValue);
    return resultClass;
  };

  // goToPreviousView():void {
  //   if (this.currentOptions.secondaryGoal === "timber")
  //   {
  //     this.viewManagementService.setTimberView(this.currentOptions);
  //   }
  //   else {
  //     this.viewManagementService.setSecondaryGoalView(this.currentOptions);
  //   }
  //   // if (this.currentOptions.secondaryGoal === "timber" && this.currentOptions.primaryGoal !== "timber")
  //   // {
  //   //   this.viewManagementService.setPrimaryGoalView(this.currentOptions);
  //   // }
  // }

  // startOver():void {
  //   this.viewManagementService.setHomeView();
  // }

  // showInfo():void {
  //   this.modalService.open.emit(null);
  // }
}
