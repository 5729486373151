import { Component, OnInit, ViewChild, ComponentFactoryResolver, Input } from '@angular/core';
import { InfoHostDirective } from '../../modal/info-host.directive';
import { ModalService } from '../../services/modal.service';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  @ViewChild("infoModal") private infoModal;
  @ViewChild(InfoHostDirective) infoHost: InfoHostDirective;

  @Input() themeValue:number;


  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private modalService: ModalService,
    private themeService: ThemeService,
  ) { }

  ngOnInit() {
    //this.loadComponent();
    this.modalService.open.subscribe(
      openModal => {
          this.infoModal.show();
      }
    );
    this.modalService.update.subscribe(
      comp => {
        this.loadComponent(comp);
      }
    );
  }

  loadComponent(comp:any):void {
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(comp);
    let viewContainerRef = this.infoHost.viewContainerRef;
    viewContainerRef.clear();
    let componentRef = viewContainerRef.createComponent(componentFactory);
  }

}
