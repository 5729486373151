import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewManagementService } from '../../services/view-management.service';
import { UserOptions } from '../../models/UserOptions';
import { ModalService } from '../../services/modal.service';
import { LegalAccessInfoComponent } from '../../modal/legal-access-info/legal-access-info.component';
import { ThemeService } from '../../services/theme.service';
import { ButtonClassService } from '../../services/button-class.service';

@Component({
  selector: 'app-legal-access',
  templateUrl: './legal-access.component.html',
  styleUrls: ['./legal-access.component.scss']
})
export class LegalAccessComponent implements OnInit {

  @Input() currentOptions:UserOptions;

  private themeValue:number;

  constructor(
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private modalService: ModalService,
    private themeService: ThemeService,
    private buttonClassService: ButtonClassService
  ) { 
    this.themeValue = this.themeService.getThemevalue();
  }

  ngOnInit() {
    this.modalService.updateInfo(LegalAccessInfoComponent);
    //console.log(this.currentOptions);
  }

  goToSubsurfaceRightsView(evt):void{
    if (evt.target.id === "yesLegalBtn")
    {
      this.currentOptions.legalAccess = true;
    }
    else {
      this.currentOptions.legalAccess = false;
    }

    //this.userOptionsService.updateOptions(this.currentOptions);
    this.viewManagementService.setSubsurfaceRightsView(this.currentOptions);
  }

  getClass(isSelected:boolean):string {
    let resultClass:string = this.buttonClassService.buildClass(isSelected, this.themeValue);
    return resultClass;
  };

  // goToTrailsRoadsView():void{
  //   this.viewManagementService.setTrailsRoadsView(this.currentOptions);
  // }

  // startOver():void{
  //   this.viewManagementService.setHomeView();
  // }

  // showInfo():void {
  //   this.modalService.open.emit(null);
  // }
}
