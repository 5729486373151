import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewManagementService } from '../../services/view-management.service';
import { UserOptions } from '../../models/UserOptions';
import { ModalService } from '../../services/modal.service';
import { SubsurfaceRightsInfoComponent } from '../../modal/subsurface-rights-info/subsurface-rights-info.component';
import { ThemeService } from '../../services/theme.service';
import { ButtonClassService } from '../../services/button-class.service';

@Component({
  selector: 'app-subsurface-rights',
  templateUrl: './subsurface-rights.component.html',
  styleUrls: ['./subsurface-rights.component.scss']
})
export class SubsurfaceRightsComponent implements OnInit {

  @Input() currentOptions: UserOptions;

  private themeValue: number;

  previousView: string;

  constructor(
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private modalService: ModalService,
    private themeService: ThemeService,
    private buttonClassService: ButtonClassService
  ) {
    this.themeValue = this.themeService.getThemevalue();
  }

  ngOnInit() {
    this.modalService.updateInfo(SubsurfaceRightsInfoComponent);
    if (this.currentOptions["propertyInMind"] === false) {
      this.previousView = "propertyInMind";
      //this.viewManagementService.setPropertyInMindView(this.currentOptions);
    }
    else {
      this.previousView = "legalAccess";
      //this.viewManagementService.setLegalAccessView(this.currentOptions);
    }
    //console.log(this.currentOptions);
  }

  goToWaterBodiesView(evt): void {
    if (evt.target.id === "yesSubBtn") {
      this.currentOptions.subsurfaceRights = true;
    }
    else {
      this.currentOptions.subsurfaceRights = false;
    }

    //this.userOptionsService.updateOptions(this.currentOptions);
    this.viewManagementService.setWaterBodiesView(this.currentOptions);
  }

  getClass(isSelected: boolean): string {
    let resultClass: string = this.buttonClassService.buildClass(isSelected, this.themeValue);
    return resultClass;
  };

  // goToPreviousView():void{
  //   if (this.currentOptions["propertyInMind"] === false)
  //   {
  //     this.viewManagementService.setPropertyInMindView(this.currentOptions);
  //   }
  //   else
  //   {
  //     this.viewManagementService.setLegalAccessView(this.currentOptions);
  //   }
  // }

  // startOver():void{
  //   this.viewManagementService.setHomeView();
  // }

  // showInfo():void {
  //   this.modalService.open.emit(null);
  // }
}
