import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';
import { ViewManagementService } from '../../services/view-management.service';
import { UserOptions } from '../../models/UserOptions';
import { UserOptionsService } from '../../services/user-options.service';
import { ModalService } from '../../services/modal.service';
import { LandSizeInfoComponent } from '../../modal/land-size-info/land-size-info.component';
import { ThemeService } from '../../services/theme.service';
import { ButtonClassService } from '../../services/button-class.service';

@Component({
  selector: 'app-land-size',
  templateUrl: './land-size.component.html',
  styleUrls: ['./land-size.component.scss']
})
export class LandSizeComponent implements OnInit {

  @Input() currentOptions:UserOptions;

  private themeValue:number;

  constructor(
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private modalService: ModalService,
    private themeService: ThemeService,
    private buttonClassService: ButtonClassService
  ) { 
    this.themeValue = this.themeService.getThemevalue();
  }

  ngOnInit() {
    this.modalService.updateInfo(LandSizeInfoComponent);
    //console.log(this.currentOptions);
  }

  goToPrimaryGoalView(evt):void {
    switch (evt.target.id) {
      case "lessThan10Btn":
      this.currentOptions.landSize = "lessThan10";
      break;

      case "tenTo100Btn":
      this.currentOptions.landSize = "tenTo100";
      break;

      case "hundredTo500Btn":
      this.currentOptions.landSize = "hundredTo500";
      break;

      case "moreThan500Btn":
      this.currentOptions.landSize = "moreThan500";
      break;
    }
    //this.userOptionsService.updateOptions(this.currentOptions);
    this.viewManagementService.setPrimaryGoalView(this.currentOptions);
  }

  getClass(isSelected:boolean):string {
    let resultClass:string = this.buttonClassService.buildClass(isSelected, this.themeValue);
    return resultClass;
  };

  // goToRegionView():void {
  //   this.viewManagementService.setRegionView(this.currentOptions);
  // }

  // startOver():void {
  //   this.viewManagementService.setHomeView();
  // }

  // showInfo():void {
  //   this.modalService.open.emit(null);
  // }
}
