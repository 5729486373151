import { Component, OnInit, Input } from '@angular/core';
import { UserOptions } from '../../models/UserOptions';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';
import { ViewManagementService } from '../../services/view-management.service';
import { ModalService } from '../../services/modal.service';
import { EndangeredInfoComponent } from '../../modal/endangered-info/endangered-info.component';
import { ThemeService } from '../../services/theme.service';
import { ButtonClassService } from '../../services/button-class.service';

@Component({
  selector: 'app-endangered',
  templateUrl: './endangered.component.html',
  styleUrls: ['./endangered.component.scss']
})
export class EndangeredComponent implements OnInit {

  @Input() currentOptions:UserOptions;

  private themeValue:number;

  constructor(
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private modalService: ModalService,
    private themeService: ThemeService,
    private buttonClassService: ButtonClassService
  ) { 
    this.themeValue = this.themeService.getThemevalue();
  }

  ngOnInit() {
    this.modalService.updateInfo(EndangeredInfoComponent);
    //console.log(this.currentOptions);
  }  

  goToRegionView(evt):void{
    if (evt.target.id === "yesEndBtn")
    {
      this.currentOptions.endangered = true;
    }
    else {
      this.currentOptions.endangered = false;
    }

    //this.userOptionsService.updateOptions(this.currentOptions);
    this.viewManagementService.setRegionView(this.currentOptions);
  }

  getClass(isSelected:boolean):string {
    let resultClass:string = this.buttonClassService.buildClass(isSelected, this.themeValue);
    return resultClass;
  };

  // goToSubsurfaceRightsView(evt):void{
  //   this.viewManagementService.setSubsurfaceRightsView(this.currentOptions);
  // }

  // startOver():void{
  //   this.viewManagementService.setHomeView();
  // }

  // showInfo():void {
  //   this.modalService.open.emit(null);
  // }
}
