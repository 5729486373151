import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewManagementService } from '../../services/view-management.service';
import { UserOptions } from '../../models/UserOptions';
import { ModalService } from '../../services/modal.service';
import { AgriculturalInfoComponent } from '../../modal/agricultural-info/agricultural-info.component';
import { ButtonClassService } from '../../services/button-class.service';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-agricultural',
  templateUrl: './agricultural.component.html',
  styleUrls: ['./agricultural.component.scss']
})
export class AgriculturalComponent implements OnInit {

  @Input() currentOptions:UserOptions;

  private themeValue:number;

  constructor(
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private modalService: ModalService,
    private themeService: ThemeService,
    private buttonClassService: ButtonClassService
  ) {
    this.themeValue = this.themeService.getThemevalue();
   }

  ngOnInit() {
    this.modalService.updateInfo(AgriculturalInfoComponent);
    //console.log(this.currentOptions);
  }

  goToTrailsRoadsView(evt):void {
    if (evt.target.id === "yesAgBtn")
    {
      this.currentOptions.agricultural = true;
    }
    else {
      this.currentOptions.agricultural = false;
    }

    //this.userOptionsService.updateOptions(this.currentOptions);
    this.viewManagementService.setTrailsRoadsView(this.currentOptions);
  }

  getClass(isSelected:boolean):string {
    let resultClass:string = this.buttonClassService.buildClass(isSelected, this.themeValue);
    return resultClass;
  };

  // goToClearTitleView():void{
  //   this.viewManagementService.setClearTitleView(this.currentOptions);
  // }

  // startOver():void{
  //   this.viewManagementService.setHomeView();
  // }

  // showInfo():void {
  //   this.modalService.open.emit(null);
  // }
}
