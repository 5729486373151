import { Component, OnInit, Input } from '@angular/core';
import { ViewManagementService } from '../../services/view-management.service';
import { ModalService } from '../../services/modal.service';
import { ThemeService } from '../../services/theme.service';
import { UserOptions } from '../../models/UserOptions';

@Component({
  selector: 'app-icons-bar',
  templateUrl: './icons-bar.component.html',
  styleUrls: ['./icons-bar.component.scss']
})
export class IconsBarComponent implements OnInit {

  @Input() prevView: string;
  @Input() currentOptions: UserOptions;
  @Input() isReport: boolean;

  themeValue: number;

  constructor(
    private viewManagementService: ViewManagementService,
    private modalService: ModalService,
    private themeService: ThemeService
  ) {
    this.themeValue = themeService.getThemevalue();
  }

  ngOnInit() {

  }

  goBack(): void {
    switch (this.prevView) {
      case "agricultural":
        this.viewManagementService.setAgriculturalView(this.currentOptions);
        break;

      case "clearTitle":
        this.viewManagementService.setClearTitleView(this.currentOptions);
        break;

      case "currentMap":
        this.viewManagementService.setCurrentMapView(this.currentOptions);
        break;

      case "endangered":
        this.viewManagementService.setEndangeredView(this.currentOptions);
        break;

      case "home":
        this.viewManagementService.setHomeView();
        break;

      case "landSize":
        this.viewManagementService.setLandSizeView(this.currentOptions);
        break;

      case "landUse":
        this.viewManagementService.setPreviousLandView(this.currentOptions);
        break;

        case "legalAccess":
        this.viewManagementService.setLegalAccessView(this.currentOptions);
        break;

      case "ownershipType":
        this.viewManagementService.setOwnershipTypeView(this.currentOptions);
        break;

      case "primaryGoal":
        this.viewManagementService.setPrimaryGoalView(this.currentOptions);
        break;

      case "propertyInMind":
        this.viewManagementService.setPropertyInMindView(this.currentOptions);
        break;

      case "region":
        this.viewManagementService.setRegionView(this.currentOptions);
        break;

      case "secondaryGoal":
        this.viewManagementService.setSecondaryGoalView(this.currentOptions);
        break;

      case "subsurfaceRights":
        this.viewManagementService.setSubsurfaceRightsView(this.currentOptions);
        break;

      case "timber":
        this.viewManagementService.setTimberView(this.currentOptions);
        break;

      case "trailsRoads":
        this.viewManagementService.setTrailsRoadsView(this.currentOptions);
        break;

        case "waterBodies":
        this.viewManagementService.setWaterBodiesView(this.currentOptions);
        break;

      case "wildlife":
        this.viewManagementService.setWildlifeView(this.currentOptions);
        break;
    }
  };

  startOver(): void {
    this.viewManagementService.setHomeView();
  }

  showInfo(): void {
    this.modalService.open.emit(null);
  }

}
