import { Component, OnInit, Input } from '@angular/core';
import { ViewManagementService } from '../../services/view-management.service';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';
import { UserOptions } from '../../models/UserOptions';
import { ModalService } from '../../services/modal.service';
import { PrimaryGoalInfoComponent } from '../../modal/primary-goal-info/primary-goal-info.component';
import { ThemeService } from '../../services/theme.service';
import { ButtonClassService } from '../../services/button-class.service';

@Component({
  selector: 'app-primary-goal',
  templateUrl: './primary-goal.component.html',
  styleUrls: ['./primary-goal.component.scss']
})
export class PrimaryGoalComponent implements OnInit {

  @Input() currentOptions:UserOptions;

  private themeValue:number;

  constructor(
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private modalService: ModalService,
    private themeService: ThemeService,
    private buttonClassService: ButtonClassService
  ) { 
    this.themeValue = this.themeService.getThemevalue();
  }

  ngOnInit() {
    this.modalService.updateInfo(PrimaryGoalInfoComponent);
    //console.log(this.currentOptions);
  }

  goToTimberView(evt):void {
    this.currentOptions.timberType = null;
    this.currentOptions.primaryGoal = "timber";
    this.currentOptions.isTimberTypeFromSecondary = false;
    //this.userOptionsService.updateOptions(this.currentOptions);
    this.viewManagementService.setTimberView(this.currentOptions);
  }

  goToSecondaryObjectiveView(evt):void {
    switch (evt.target.id) {
      case "orchardBtn":
      this.currentOptions.primaryGoal = "orchard";
      break;

      case "wildlifeBtn":
      this.currentOptions.primaryGoal = "wildlife";
      this.currentOptions.thirdGoal = false;
      break;

      case "livestockBtn":
      this.currentOptions.primaryGoal = "livestock";
      break;

      case "cropsBtn":
      this.currentOptions.primaryGoal = "crops";
      break;
    }
    //this.userOptionsService.updateOptions(this.currentOptions);
    this.viewManagementService.setSecondaryGoalView(this.currentOptions);
  }

  getClass(isSelected:boolean):string {
    let resultClass:string = this.buttonClassService.buildClass(isSelected, this.themeValue);
    return resultClass;
  };

  // goToLandSizeView(evt):void {
  //   this.viewManagementService.setLandSizeView(this.currentOptions);
  // }

  // startOver(evt):void {
  //   this.viewManagementService.setHomeView();
  // }

  // showInfo():void {
  //   this.modalService.open.emit(null);
  // }
}
