import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewManagementService } from '../../services/view-management.service';
import { UserOptions } from '../../models/UserOptions';
import { ModalService } from '../../services/modal.service';
import { PrevLandUseInfoComponent } from '../../modal/prev-land-use-info/prev-land-use-info.component';
import { ThemeService } from '../../services/theme.service';
import { ButtonClassService } from '../../services/button-class.service';

@Component({
  selector: 'app-prev-land-use',
  templateUrl: './prev-land-use.component.html',
  styleUrls: ['./prev-land-use.component.scss']
})
export class PrevLandUseComponent implements OnInit {

  @Input() currentOptions:UserOptions;

  private themeValue:number;

  constructor(
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private modalService: ModalService,
    private themeService: ThemeService,
    private buttonClassService: ButtonClassService
  ) { 
    this.themeValue = this.themeService.getThemevalue();
  }

  ngOnInit() {
    this.modalService.updateInfo(PrevLandUseInfoComponent);
    //console.log(this.currentOptions);
  }

  goToClearTitle(evt): void {
    if (evt.target.id === "yesLandBtn")
    {
      this.currentOptions.prevLandUse = true;
    }
    else {
      this.currentOptions.prevLandUse = false;
    }

    //this.userOptionsService.updateOptions(this.currentOptions);
    this.viewManagementService.setClearTitleView(this.currentOptions);
  }

  getClass(isSelected:boolean):string {
    let resultClass:string = this.buttonClassService.buildClass(isSelected, this.themeValue);
    return resultClass;
  };

  // goToCurrentMapView(): void {
  //   this.viewManagementService.setCurrentMapView(this.currentOptions);
  // }

  // startOver():void{
  //   this.viewManagementService.setHomeView();
  // }
  
  // showInfo():void {
  //   this.modalService.open.emit(null);
  // }
}
