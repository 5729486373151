import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private domain:string = "https://gotland.tfs.tamu.edu/Report/api/report/";
  //private domain:string = "http://tfs-26154/gotland/Report/api/report/";
  private reportUrl:string = this.domain + "build";

  constructor(
    private http: HttpClient
  ) { }

  getReport(data : {content:string}):Observable<any> {
    //const params:string = JSON.stringify(reportParams);
    const url = `${this.reportUrl}`;

    return this.http.post<any>(url, data);
  }
}
