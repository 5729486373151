import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';
import { ViewManagementService } from '../../services/view-management.service';
import { UserOptions } from '../../models/UserOptions';
import { ModalService } from '../../services/modal.service';
import { RegionInfoComponent } from '../../modal/region-info/region-info.component';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss']
})
export class RegionComponent implements OnInit {

  @Input() currentOptions:UserOptions;

  private isContainerLandscape:boolean;

  constructor(
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.modalService.updateInfo(RegionInfoComponent); 
  }
}
