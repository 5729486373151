import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-ownership-info',
  templateUrl: './ownership-info.component.html',
  styleUrls: ['./ownership-info.component.scss']
})
export class OwnershipInfoComponent implements OnInit {

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

}
