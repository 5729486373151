import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewManagementService } from './services/view-management.service';
import { UserOptions } from './models/UserOptions';
import { UserOptionsService } from './services/user-options.service';
import { ThemeService } from './services/theme.service';
import { ResizeService } from './services/resize.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class AppComponent {

  currentView:string = "Home";
  currentOptions:UserOptions = new UserOptions();
  
  progressValue:number = 0;

  themeValue:number = Math.floor(Math.random() * 3) +1;
  //themeValue:number = 3;

  constructor( 
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private userOptionsService: UserOptionsService,
    private themeService: ThemeService,
    private _resizeService: ResizeService
   ) { 

    //This class needs to be added to change styles on hover. This behavior was bugging iphones and ipads browsers
    if (!("ontouchstart" in document.documentElement) || !this.isMobileDevice())
    {
      document.body.classList.add("no-touch");
    }

     viewManagementService.setView.subscribe(view => {
       this.currentView = view;
     });

     viewManagementService.progressValue.subscribe(value => {
       this.progressValue = value;
     });

     userOptionsService.update.subscribe(_options => {
       this.currentOptions = _options;
     })


     themeService.setThemeValue(this.themeValue);

     this.updateDeviceWidth(window.innerWidth, window.innerHeight);
   }

   //Check if is mobile
   isMobileDevice() {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
   }

   onResize(event) {
    this.updateDeviceWidth(event.target.innerWidth, event.target.innerHeight);
  }

  updateDeviceWidth(newWidth: number, newHeight: number): void {

    this._resizeService.updateSize(newWidth, newHeight);
  }  
}