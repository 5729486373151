import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewManagementService } from '../../services/view-management.service';
import { UserOptions } from '../../models/UserOptions';
import { ModalService } from '../../services/modal.service';
import { PropertyInMindInfoComponent } from '../../modal/property-in-mind-info/property-in-mind-info.component';
import { ThemeService } from '../../services/theme.service';
import { ButtonClassService } from '../../services/button-class.service';

@Component({
  selector: 'app-property-in-mind',
  templateUrl: './property-in-mind.component.html',
  styleUrls: ['./property-in-mind.component.scss']
})
export class PropertyInMindComponent implements OnInit {

  @Input() currentOptions: UserOptions;

  private themeValue:number;

  constructor(
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private modalService: ModalService,
    private themeService: ThemeService,
    private buttonClassService: ButtonClassService
  ) { 
    this.themeValue = this.themeService.getThemevalue();
  }

  ngOnInit() {
    this.modalService.updateInfo(PropertyInMindInfoComponent);
    //console.log(this.currentOptions);
  }

  goToCurrentMapView():void {
    this.currentOptions.propertyInMind = true;
    this.viewManagementService.setCurrentMapView(this.currentOptions);
  }

  goToSubsurfaceRightsView():void {
    this.currentOptions.propertyInMind = false;
    this.viewManagementService.setSubsurfaceRightsView(this.currentOptions);
  }

  getClass(isSelected:boolean):string {
    let resultClass:string = this.buttonClassService.buildClass(isSelected, this.themeValue);
    return resultClass;
  };

  // startOver():void {
  //   this.viewManagementService.setHomeView();
  // }

  // goToOwnershipTypeView():void {
  //   this.viewManagementService.setOwnershipTypeView(this.currentOptions);
  // }

  // showInfo():void {
  //   this.modalService.open.emit(null);
  // }
}
