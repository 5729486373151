import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ButtonClassService {

  constructor() { }

  buildClass(isSelected:boolean, themeValue:number):string {
    let resultClass:string = "";

    if (isSelected === true)
    {
      resultClass = "selectedBtn-t";
    }
    else
    {
      resultClass = "optionBtn-t";
    }

    resultClass = resultClass + themeValue.toString();

    return resultClass;
  }

  buildMapRegionClass(isSelected:boolean, themeValue:number, isText:boolean, isHovered:boolean):string {
    let resultClass:string = "";
    if (isSelected === true || isHovered === true)
    {
      if (isText === false)
      {
        resultClass = "selectedRegion-t";
      }
      else {
        resultClass = "selectedRegionText-t";
      }
    }
    else {
      if (isText === false)
      {
        resultClass = "regionPath-t";
      }
      else {
        resultClass = "regionText-t";
      }
    }

    resultClass = resultClass + themeValue.toString();

    return resultClass;
  }
}
