import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';
import { ViewManagementService } from '../../services/view-management.service';
import { UserOptions } from '../../models/UserOptions';
import { ModalService } from '../../services/modal.service';
import { SecondaryGoalInfoComponent } from '../../modal/secondary-goal-info/secondary-goal-info.component';
import { ThemeService } from '../../services/theme.service';
import { ButtonClassService } from '../../services/button-class.service';

@Component({
  selector: 'app-secondary-goal',
  templateUrl: './secondary-goal.component.html',
  styleUrls: ['./secondary-goal.component.scss']
})
export class SecondaryGoalComponent implements OnInit {

  @Input() currentOptions:UserOptions;

  private themeValue:number;

  previousView:string;

  constructor(
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private modalService: ModalService,
    private themeService: ThemeService,
    private buttonClassService: ButtonClassService
  ) { 
    this.themeValue = this.themeService.getThemevalue();
  }

  ngOnInit() {
    this.modalService.updateInfo(SecondaryGoalInfoComponent);
    
    if (this.currentOptions.primaryGoal === "timber")
    {
      this.previousView = "timber";
      //this.viewManagementService.setTimberView(this.currentOptions);
    }
    else {
      this.previousView = "primaryGoal";
      //this.viewManagementService.setPrimaryGoalView(this.currentOptions);
    } 
    //console.log(this.currentOptions);
  }

  // goToTimberView():void {
  //   this.currentOptions.secondaryGoal = "timber";
  //   //this.userOptionsService.updateOptions(this.currentOptions);
  //   this.viewManagementService.setTimberView(this.currentOptions, true);
  // }

  goToNextView(evt):void {
    switch(evt.target.id)
    {
      case "timberBtn":
      this.currentOptions.timberType = null;
      this.currentOptions.secondaryGoal = "timber";
      this.currentOptions.isTimberTypeFromSecondary = true;
      //this.userOptionsService.updateOptions(this.currentOptions);
      this.viewManagementService.setTimberView(this.currentOptions);
      break;

      case "orchardBtn":
      this.currentOptions.secondaryGoal = "orchard";
      //this.userOptionsService.updateOptions(this.currentOptions);
      if (this.currentOptions.primaryGoal !== "wildlife"){
        this.viewManagementService.setWildlifeView(this.currentOptions);
      }
      else {
        this.viewManagementService.setReportView(this.currentOptions);
      }
      break;

      case "wildlifeBtn":
      this.currentOptions.secondaryGoal = "wildlife";
      this.currentOptions.thirdGoal = false;
      //this.userOptionsService.updateOptions(this.currentOptions);
      this.viewManagementService.setReportView(this.currentOptions);
      break;

      case "livestockBtn":
      this.currentOptions.secondaryGoal = "livestock";
      //this.userOptionsService.updateOptions(this.currentOptions);
      if (this.currentOptions.primaryGoal !== "wildlife"){
        this.viewManagementService.setWildlifeView(this.currentOptions);
      }
      else {
        this.viewManagementService.setReportView(this.currentOptions);
      }
      break;

      case "cropsBtn":
      this.currentOptions.secondaryGoal = "crops";
      //this.userOptionsService.updateOptions(this.currentOptions);
      if (this.currentOptions.primaryGoal !== "wildlife"){
        this.viewManagementService.setWildlifeView(this.currentOptions);
      }
      else {
        this.viewManagementService.setReportView(this.currentOptions);
      }
      break;

      case "noneBtn":
      //this.userOptionsService.updateOptions(this.currentOptions);
      this.currentOptions.secondaryGoal = "none";
      this.viewManagementService.setReportView(this.currentOptions);
      break;
    }
  }

  getClass(isSelected:boolean):string {
    let resultClass:string = this.buttonClassService.buildClass(isSelected, this.themeValue);
    return resultClass;
  };

  // goToPreviousView():void {
  //   if (this.currentOptions.primaryGoal === "timber")
  //   {
  //     this.viewManagementService.setTimberView(this.currentOptions);
  //   }
  //   else {
  //     this.viewManagementService.setPrimaryGoalView(this.currentOptions);
  //   }    
  // }

  // startOver():void {
  //   this.viewManagementService.setHomeView();
  // }

  // showInfo():void {
  //   this.modalService.open.emit(null);
  // }
}
