import { Injectable, Output, EventEmitter } from '@angular/core';
import { UserOptionsService } from './user-options.service';
import { UserOptions } from '../models/UserOptions';

@Injectable({
  providedIn: 'root'
})
export class ViewManagementService {

  @Output() setView: EventEmitter<string> = new EventEmitter();
  @Output() progressValue: EventEmitter<number> = new EventEmitter();

  //private currentView:string = "Home";
  private totalViews = 19;

  constructor(
    private userOptionsService: UserOptionsService
  ) {
   }

  // updateView(viewName:string): void {
  //   this.currentView = viewName;
  //   this.setView.emit(this.currentView);
  // }

  setHomeView():void {
    this.userOptionsService.resetOptions();
    this.setView.emit("Home");
  }

  setOwnershipTypeView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("OwnershipType");
    this.progressValue.emit(Math.round( 1 / this.totalViews * 100));
  }

  setPropertyInMindView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("PropertyInMind");
    this.progressValue.emit(Math.round( 2 / this.totalViews * 100));
  }

  setCurrentMapView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("CurrentMap");
    this.progressValue.emit(Math.round( 3 / this.totalViews * 100));
  }

  setPreviousLandView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("PreviousLand");
    this.progressValue.emit(Math.round( 4 / this.totalViews * 100));
  }

  setClearTitleView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("ClearTitle");
    this.progressValue.emit(Math.round( 5 / this.totalViews * 100));
  }

  setAgriculturalView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("Agricultural");
    this.progressValue.emit(Math.round( 6 / this.totalViews * 100));
  }

  setTrailsRoadsView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("TrailsRoads");
    this.progressValue.emit(Math.round( 7 / this.totalViews * 100));
  }

  setLegalAccessView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("LegalAccess");
    this.progressValue.emit(Math.round( 8 / this.totalViews * 100));
  }

  setSubsurfaceRightsView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("SubsurfaceRights");
    this.progressValue.emit(Math.round( 9 / this.totalViews * 100));
  }

  setWaterBodiesView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("WaterBodies");
    this.progressValue.emit(Math.round( 10 / this.totalViews * 100));
  }

  setEndangeredView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("Endangered");
    this.progressValue.emit(Math.round( 11 / this.totalViews * 100));
  }

  setRegionView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("Region");
    this.progressValue.emit(Math.round( 12 / this.totalViews * 100));
  }

  setLandSizeView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("LandSize");
    this.progressValue.emit(Math.round( 13 / this.totalViews * 100));
  }

  setPrimaryGoalView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("PrimaryGoal");
    this.progressValue.emit(Math.round( 14 / this.totalViews * 100));
  }

  setTimberView(userOptions:UserOptions):void {
    let viewNumber:number = 15;
    if (userOptions.secondaryGoal === 'timber')
    {
      viewNumber = 17;
    }
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("Timber");
    this.progressValue.emit(Math.round( viewNumber / this.totalViews * 100));
  }

  setSecondaryGoalView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("SecondaryGoal");
    this.progressValue.emit(Math.round( 16 / this.totalViews * 100));
  }

  setWildlifeView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("Wildlife");
    this.progressValue.emit(Math.round( 18 / this.totalViews * 100));
  }

  setReportView(userOptions:UserOptions):void {
    this.userOptionsService.updateOptions(userOptions);
    this.setView.emit("Report");
    this.progressValue.emit(Math.round( 19 / this.totalViews * 100));
  }
}
