import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewManagementService } from '../../services/view-management.service';
import { UserOptions } from '../../models/UserOptions';
import { ModalService } from '../../services/modal.service';
import { OwnershipInfoComponent } from '../../modal/ownership-info/ownership-info.component';
import { ThemeService } from '../../services/theme.service';
import { ButtonClassService } from '../../services/button-class.service';

@Component({
  selector: 'app-ownership-type',
  templateUrl: './ownership-type.component.html',
  styleUrls: ['./ownership-type.component.scss']
})
export class OwnershipTypeComponent implements OnInit {

  @Input() currentOptions: UserOptions;

  private themeValue:number;

  constructor(
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private modalService: ModalService,
    private themeService: ThemeService,
    private buttonClassService: ButtonClassService
  ) { 
    this.themeValue = this.themeService.getThemevalue();
  }

  ngOnInit() {
    this.modalService.updateInfo(OwnershipInfoComponent);
    //console.log(this.currentOptions);
  }

  // startOver(): void {
  //   this.viewManagementService.setHomeView();
  // }

  goToPropertyInMindView(): void {
    this.currentOptions.ownershipType = "purchasing";
    this.viewManagementService.setPropertyInMindView(this.currentOptions);
  }

  goToCurrentMapView(): void {
    this.currentOptions.ownershipType = "owned";
    this.currentOptions.propertyInMind = undefined;
    this.viewManagementService.setCurrentMapView(this.currentOptions);
  }

  getClass(isSelected:boolean):string {
    let resultClass:string = this.buttonClassService.buildClass(isSelected, this.themeValue);
    return resultClass;
  };

  // showInfo():void {
  //   this.modalService.open.emit(null);
  // }
}
