export class UserOptions {
    ownershipType: string;
    propertyInMind: boolean;
    currentMap: boolean;
    prevLandUse: boolean;
    clearTitle: boolean;
    agricultural: boolean;
    trailsRoads: boolean;
    legalAccess: boolean;
    subsurfaceRights: boolean;
    waterBodies: boolean;
    endangered: boolean;
    region: string;
    landSize: string;
    primaryGoal: string;
    timberType: string;
    isTimberTypeFromSecondary: boolean;
    secondaryGoal: string;
    thirdGoal: boolean;
}