import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-subsurface-rights-info',
  templateUrl: './subsurface-rights-info.component.html',
  styleUrls: ['./subsurface-rights-info.component.scss']
})
export class SubsurfaceRightsInfoComponent implements OnInit {

  constructor(
    private translate: TranslateService
  ) { }
  
  ngOnInit() {
  }

}
