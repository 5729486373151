import { Injectable, Output, EventEmitter } from '@angular/core';
import { UserOptions } from '../models/UserOptions';

@Injectable({
  providedIn: 'root'
})
export class UserOptionsService {

  @Output() update: EventEmitter<UserOptions> = new EventEmitter();

  private currentOptions: UserOptions = new UserOptions();

  constructor() { }

  updateOptions(_options: UserOptions): void {
    this.currentOptions = _options;
    this.update.emit(this.currentOptions);
  }

  resetOptions():void {
    this.currentOptions = new UserOptions();
    this.update.emit(this.currentOptions);
  }
}
