import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';
import { ViewManagementService } from '../../services/view-management.service';
import { UserOptionsService } from '../../services/user-options.service';
import { UserOptions } from '../../models/UserOptions';
import { ModalService } from '../../services/modal.service';
import { TimberInfoComponent } from '../../modal/timber-info/timber-info.component';
import { ThemeService } from '../../services/theme.service';
import { ButtonClassService } from '../../services/button-class.service';

@Component({
  selector: 'app-timber',
  templateUrl: './timber.component.html',
  styleUrls: ['./timber.component.scss']
})
export class TimberComponent implements OnInit {

  @Input() currentOptions: UserOptions;

  private themeValue: number;
  previousView: string;

  constructor(
    private translate: TranslateService,
    private viewManagementService: ViewManagementService,
    private modalService: ModalService,
    private themeService: ThemeService,
    private buttonClassService: ButtonClassService
  ) {
    this.themeValue = this.themeService.getThemevalue();
  }

  ngOnInit() {
    this.modalService.updateInfo(TimberInfoComponent);

    if (this.currentOptions.isTimberTypeFromSecondary === false) {
      this.previousView = "primaryGoal";
      //this.viewManagementService.setPrimaryGoalView(this.currentOptions);
    }
    else {
      this.previousView = "secondaryGoal";
      //this.viewManagementService.setSecondaryGoalView(this.currentOptions);
    }
    //console.log(this.currentOptions);
  }

  goToNextView(evt): void {
    switch (evt.target.id) {
      case "pineBtn":
        this.currentOptions.timberType = "pine";
        break;

      case "hardwoodBtn":
        this.currentOptions.timberType = "hardwood";
        break;

      case "bothBtn":
        this.currentOptions.timberType = "both";
        break;
    }

    //this.userOptionsService.updateOptions(this.currentOptions);
    if (this.currentOptions.isTimberTypeFromSecondary === false) {
      this.viewManagementService.setSecondaryGoalView(this.currentOptions);
    }
    else {
      if (this.currentOptions.primaryGoal === "wildlife") {
        this.viewManagementService.setReportView(this.currentOptions);
      }
      else {
        this.viewManagementService.setWildlifeView(this.currentOptions);
      }
    }

    // if (this.currentOptions.primaryGoal === "timber") {
    //   this.viewManagementService.setSecondaryGoalView(this.currentOptions);
    // }
    // else if (this.currentOptions.primaryGoal !== "wildlife") {
    //   this.viewManagementService.setWildlifeView(this.currentOptions);
    // }
  }

  getClass(isSelected: boolean): string {
    let resultClass: string = this.buttonClassService.buildClass(isSelected, this.themeValue);
    return resultClass;
  };

  // goToPreviousView(): void {
  //   if (this.currentOptions.isTimberTypeFromSecondary === false) {
  //     this.viewManagementService.setPrimaryGoalView(this.currentOptions);
  //   }
  //   else {
  //     this.viewManagementService.setSecondaryGoalView(this.currentOptions);
  //   }
  // }

  // startOver(): void {
  //   this.viewManagementService.setHomeView();
  // }

  // showInfo():void {
  //   this.modalService.open.emit(null);
  // }
}
