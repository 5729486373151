import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-wildlife-info',
  templateUrl: './wildlife-info.component.html',
  styleUrls: ['./wildlife-info.component.scss']
})
export class WildlifeInfoComponent implements OnInit {

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

}
