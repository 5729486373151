import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-timber-info',
  templateUrl: './timber-info.component.html',
  styleUrls: ['./timber-info.component.scss']
})
export class TimberInfoComponent implements OnInit {

  constructor(
    private translate: TranslateService
  ) { }
  
  ngOnInit() {
  }

}
